import * as React from "react";

import { createSign, createVerify } from 'crypto-browserify'
import rentAHandPrivateKey from "../paymentKeys/privateKey";

import { Buffer } from 'buffer';
if (typeof window !== 'undefined') {
    window.Buffer = Buffer;
}

const generateRandomHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

const generateRandomOrderNumber = () => {
    let randomNumber = ''

    for (let index = 0; index < 6; index++) {
        randomNumber += Math.floor(Math.random() * 10)
    }

    return randomNumber
}


export default function Payments(props) {

    const AMOUNT = '1.00'
    const CURRENCY = 'BGN'
    const DESC = 'TEST_PAYMENT'
    const TERMINAL = 'V5401825'
    const MERCH_NAME = 'RENT A HAND OOD'
    const MERCH_URL = 'https://rentahandgatsbymaster.gatsbyjs.io'
    const MERCHANT = '6210034207'
    const EMAIL = 'boris.garkov@abv.bg'
    const TRTYPE = '1'
    const ORDER = generateRandomOrderNumber()
    const AD_CUST_BOR_ORDER_ID = ORDER + '_BANK_INFO'  // '003765ORD@700503765' // TODO=CHECK WHAT NEEDS TO BE DONE
    const COUNTRY = 'BG'
    const TIMESTAMP = new Date().toISOString().replace(/[-T:]/g, '').split('.')[0]
    const MERCH_GMT = '+03' // TODO=summer time +03, winter time +02 
    const NONCE = generateRandomHex(32).toUpperCase()
    const ADDENDUM = 'AD,TD'
    const RFU = '-'

    const data = (
        TERMINAL.length + TERMINAL
        + TRTYPE.length + TRTYPE
        + AMOUNT.length + AMOUNT
        + CURRENCY.length + CURRENCY
        + ORDER.length + ORDER
        + TIMESTAMP.length + TIMESTAMP
        + NONCE.length + NONCE
        + RFU
    )

    const sign = createSign('RSA-SHA256').update(data);

    const P_SIGN = sign.sign(rentAHandPrivateKey, 'hex').toUpperCase()


    return (
        <form action="https://3dsgate-dev.borica.bg/cgi-bin/cgi_link" method="POST">
            <input type="text" name="AMOUNT" value={AMOUNT} readOnly="readOnly" />
            <input type="text" name="CURRENCY" value={CURRENCY} readOnly="readOnly" />
            <input type="text" name="DESC" value={DESC} readOnly="readOnly" />
            <input type="text" name="TERMINAL" value={TERMINAL} readOnly="readOnly" />
            <input type="text" name="MERCH_NAME" value={MERCH_NAME} readOnly="readOnly" />
            <input type="text" name="MERCH_URL" value={MERCH_URL} readOnly="readOnly" />
            <input type="text" name="MERCHANT" value={MERCHANT} readOnly="readOnly" />
            <input type="text" name="EMAIL" value={EMAIL} readOnly="readOnly" />
            <input type="text" name="TRTYPE" value={TRTYPE} readOnly="readOnly" />
            <input type="text" name="ORDER" value={ORDER} readOnly="readOnly" />
            <input type="text" name="AD.CUST_BOR_ORDER_ID" value={AD_CUST_BOR_ORDER_ID} readOnly="readOnly" />
            <input type="text" name="COUNTRY" value={COUNTRY} readOnly="readOnly" />
            <input type="text" name="TIMESTAMP" value={TIMESTAMP} readOnly="readOnly" />
            <input type="text" name="MERCH_GMT" value={MERCH_GMT} readOnly="readOnly" />
            <input type="text" name="NONCE" value={NONCE} readOnly="readOnly" />
            <input type="text" name="ADDENDUM" value={ADDENDUM} readOnly="readOnly" />
            <input type="text" name="P_SIGN" value={P_SIGN} readOnly="readOnly" />

            <button type="submit" value='Approve'>Изпрати заявка</button>
        </form >
    )
}