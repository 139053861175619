const rentAHandPrivateKey = '-----BEGIN PRIVATE KEY-----\n'
    + 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC+5KMpDO9XQGbH'
    + '930ELvHIfMTIudX5jWYsoupkomORNf5kuUI5QPNWR48xI2OUawhtWy/dZ3ON8hBR'
    + '7H1SNtbUxFROzHyws9BlnAZ2psb8y2W1nc5JDmQ7IYjdpjxowkYKRHEEcXt3+PYz'
    + 'QczvruS4635OZKTGvaP/9WvY2asHw+tOtRVeP9k89Lka2kuCN/eRQknv9pvfE3Sm'
    + 'HhbwqQUIoq7srbfV9vuboFfNVXv2Nc+unwvIursaVBy+6I3Bm5xHIAR/K44PWhnt'
    + 'gA8U3P5laduHnx6UvNWacjBXaeykfv91826QBBwvvNTYh2cKq22tUrPRQVUdAVx8'
    + '8umPWxtpAgMBAAECggEBAIkClVDbZdWb9alEqsgb4ZHia2MebsPEhzc2X0GHhCsv'
    + 'M72bcjqhsYWVquK74bce81d6AWrJ25hfyvLh6Djf/2WK3HAKONyi0sp7J/B1V5EN'
    + 'c5zc/LhWyf3e3dsoe2c9OQQZh3yG7vV3DU5QxgSp5KQM8gTWG6ZBarpFrfhgkm7p'
    + 'I1iHsSIktodk0O/jzv10e4GbId42rt0QMWNl3CmJ3XAPicT+dwos43Ye5j/bbZnM'
    + 'HN2zu1+nrEPGeVlJUWGTJS0f9Qb9XYkLnSMjf7v8K4t0N5WrSyhxXrhIYr2SU98A'
    + 'eSHh2ws7cEpcjURRonu+mlZJtfvn9GXUOKtP8dioMfkCgYEA75ax36NyWCEuXpru'
    + 'Yjn+QYxFRyqjxFZAPJGSA1xESEOqkiAtipIML9eZoyHNuTK4+Gdt94jWtJHxs3eD'
    + 'NHCeUT71k7Oi2ZFZ0GP8iexNOrQeO3IFl/aegzO1mI4OU71EbaqN+drYFlfzSynZ'
    + '+TSpsHGh5KQChehajfAkLCJ2GrsCgYEAy/gK0fij5h53iOXXx8smn+w+YIOvZopj'
    + 'AUnPiDa399/Wp0tZ33qaVl4TuNLsmcaPCWdXmwHGuVn9Q28GHS8MNkt7cdAlo7Sg'
    + 'MfIXc3DWWBkjSptFeAL8M9l6eRSp/iWFTc5XOXHhIIMFyYy5Ce138qanzloBCVIl'
    + 'ZQCj9NCe+isCgYAz8AgJQgZkonTJ324z7vx2OzE9dznfMw9j0e7EObk3rSuJ193n'
    + 'fzeTG3rbyVUug5djihBlq81XgZfS3uKbeJppZGuaJEP2RDMjILm7MHWhdzBilJov'
    + '0i+Uo54HPyxN7Oarm2CBvHZBibldHup4wnjSnh+JUCCX5MHUqS0+QA44RwKBgFuA'
    + 'MppvSFd7Y3RuZ5ahc/paKMFzUcRWYIO7cPZSVcVFDS6PhwnSHlqQaZiIRsvVX2WA'
    + 'jrRi35tW217c94kwCfJxejdcgQYniZXeXNbUz9NFs6dMcKWd8TXxYhffRUNV4a89'
    + '2bNTewLyxC3ei0QA28ZnLPBNQBnGWDAvUV4KGMdxAoGAXGHVBbI0Q/qUsAcb+1Tn'
    + '5/9Ezye723MIp/7dnVo1ShAqaVRoR8FZOsIrij2FIjg5vXoBQ3zE5igYAc+sef91'
    + 'WR6Hqkb3iyDKjK5zcCW5BqZUc8DesL7YhItrzsJCz7Hhpnqx45RhCFtgS8r/SomZ'
    + 'c+UyrjMO12tZ+0szSbaBUfM=\n'
    + '-----END PRIVATE KEY-----'

export default rentAHandPrivateKey